<template>
    <div class="main_header">
        <div class="main_header_l">
            <div class="logo">
                <img :src="require(`@/assets/images/header/logo3.png`)">
            </div>
            <i
                class="versionMark"
                :class="{'basicEdition': $store.state.versionMark == 1,'advancedEdition': $store.state.versionMark == 2}"
            ></i>
            <!-- 租户集合 -->
            <div class="tenant" v-if="(userList || []).length > 0">
                <el-dropdown class="iconfont icon19" trigger="click">
                    <span class="el-dropdown-link iconfont iconbelow-s">{{ currentTenant }}</span>
                    <el-dropdown-menu slot="dropdown" class="tenantDropdown">
                        <div class="tenantDropdownBox">
                            <template v-for="(item, index) in userList">
                                <el-dropdown-item :key="index" @click.native="userSwitch(item)">
                                    {{ item.TenantName }}
                                </el-dropdown-item>
                            </template>
                        </div>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <!-- 功能按钮 -->
        <div class="main_header_r">
            <!-- 设置 -->
            <el-badge class="item">
                <el-button
                    size="small"
                    class="iconfont iconxitongguanli"
                    @click="handleOpenMenuAdmin()"
                >
                </el-button>
            </el-badge>
            <!-- 色块页 -->
            <div
                class="ebchome"
                @click="toEBCHome"
                v-if="isJtuser"
                title="返回首页"
            >
                <span class="iconfont iconhome1"></span>
            </div>
            <!-- 全屏 -->
            <el-badge class="item">
                <el-button
                    size="small"
                    class="iconfont iconquanping-fangda-11"
                    v-if="!isScreenFull"
                    @click="toggleFullscreen"
                >
                </el-button>
                <el-button
                    size="small"
                    class="iconfont iconsuoxiao-"
                    v-else
                    @click="toggleFullscreen"
                >
                </el-button>
            </el-badge>
            <!-- 消息中心 -->
            <el-badge class="item">
                <el-button size="small" class="iconfont iconvolume-fill" @click="showMessage"></el-button>
                <i class="dot" v-if="isHaveNewMsg"></i>
            </el-badge>
            <!-- 个人中心 -->
            <div class="user item">
                <div class="userImg">
                    <span
                        :style="{backgroundColor:userColor}"
                        class="iconfont iconminsuzhuguanli"
                    ></span>
                </div>
                <el-dropdown trigger="click">
                    <span class="el-dropdown-link">{{ user }}</span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item @click.native="clickUserInfo">
                            个人信息
                        </el-dropdown-item>
                        <el-dropdown-item v-if="getShowMenuAdminBtn()" @click.native="mockLogin">
                            模拟登录
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <!-- 退出 -->
            <el-badge class="item signOutBtn">
                <el-button
                    size="small"
                    class="el-icon-switch-button"
                    @click="signOut()"
                >
                    退出
                </el-button>
            </el-badge>
        </div>
    </div>
</template>

<script>
import { resetTokenAndClearUser, switchTokenAndClearUser } from '@/utils';
import screenfull from 'screenfull';
import cookie from '@/common/NIM/utils/cookie';
import currencyFun from '@/assets/js/currencyFun';

export default {
    props: {},
    data() {
        return {
            // 当前用户
            user: '',
            // 当前用户头像
            userImg: '',
            // 当前用户没有头像替换背景色
            userColor: '#B8C2CC',
            isHaveNewMsg: false,
            // 是否全屏
            isScreenFull: false,

            isNIM: false,
            isJtuser: false, // 是否是集团用户

            // 当前用户下租户集合
            userList: [],
            // 当前租户
            currentTenant: '',
        };
    },
    watch: {
        getUnread(newVal) {
            this.isHaveNewMsg = newVal > 0;
        },
    },
    async created() {
        this.gitUserData();
        // 获取用户信息
        this.userInfo = await this.getUserInfo();
        // 拉取当前用户下租户集合
        this.userList = await this.getTenantList();
        // 当前租户
        this.currentTenant = this.$takeTokenParameters('TenantName');
        this.isNIM = cookie.readCookie('isNIM');
        const DeptType = this.$takeTokenParameters('DeptType');
        if (['1', '2'].indexOf(DeptType) > -1) {
            this.isJtuser = true;
        } else {
            this.isJtuser = false;
        }
    },
    mounted() {
        this.initFullScreen();
        // 解决按f11时图标不变化的问题
        window.addEventListener('keydown', this.KeyDown, true);
    },
    computed: {
        getUnread() {
            // 云信会话消息-未读数
            const sessionUnread = window.$globalHub.$store.state.sessionUnread.total;
            const sysUnread = window.$globalHub.$store.state.sysUnread.sum;
            const total = sessionUnread + sysUnread;
            return total;
        },
    },
    methods: {
        // 切换用户
        userSwitch(data) {
            if (data.TenantId) {
                this.$emit('loadingState', true);
                const qs = require('qs');
                const token = window.sessionStorage.getItem('token').split(' ')[1];
                this.$axios
                    .post('/interfaceApi/login/switch_tenant', qs.stringify({
                        token: token,
                        tenantId: data.TenantId,
                    }), {
                        headers: {
                            ContentType: 'application/x-www-form-urlencoded',
                        },
                    })
                    .then(async res => {
                        sessionStorage.setItem('token', `${res.token_type} ${res.access_token}`);
                        sessionStorage.setItem('refreshToken', `${res.token_type} ${res.refresh_token}`);

                        // 保存用户信息
                        await currencyFun.getUserInfo();

                        this.gitUserData();
                        switchTokenAndClearUser();
                        this.$router.push({ path: '/' });
                        this.$emit('loadingState', false);
                    }).catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            } else {
                this.$message({
                    message: '正在当前用户下！',
                    type: 'warning',
                });
            }
        },
        // 获取用户信息
        gitUserData() {
            // 当前用户赋值
            this.user = this.$takeTokenParameters('Name') || '管理员';
            // 当前用户没有头像 取用户名称文字
            this.userImg = this.user.substring(1);
        },
        // 返回色块页
        toEBCHome() {
            this.$router.push({ path: '/EBCHome' });
        },

        // 全屏
        toggleFullscreen() {
            if (!screenfull.isEnabled) {
                this.$message({
                    message: '浏览器不能全屏',
                    type: 'warning',
                });
            } else {
                screenfull.toggle();
            }
        },
        fullScreenChange() {
            this.isScreenFull = screenfull.isFullscreen;
        },
        initFullScreen() {
            if (screenfull.isEnabled) {
                screenfull.on('change', this.fullScreenChange);
            }
        },
        destroy() {
            if (screenfull.isEnabled) {
                screenfull.off('change', this.fullScreenChange);
            }
        },
        KeyDown(event) {
            if (event.keyCode === 122) {
                // 禁用f11
                event.returnValue = false;
                // 触发全屏的按钮
                this.toggleFullscreen();
            }
        },

        // 生成随机颜色
        color() {
            const r = Math.floor(Math.random() * 256);
            const g = Math.floor(Math.random() * 256);
            const b = Math.floor(Math.random() * 256);
            const color = '#' + r.toString(16) + g.toString(16) + b.toString(16);
            return color;
        },
        // 选择主题弹出层
        choiceTheme() {
            this.choiceThemeLayer = true;
        },
        // 退出系统
        async signOut() {
            await this.exitSystemRequest();
            cookie.delCookie('isNIM');
            resetTokenAndClearUser();
            this.$router.replace({ name: 'login' });
            // window.location.reload();
        },
        // 个人信息修改
        clickUserInfo() {
            this.$toast({
                title: false,
                type: 'eject',
                width: '9rem',
                height: 'auto',
                t_url: 'userinfo/edit',
                extr: {},
            });
            this.detailedInfo = true;
            this.getUserInfo();
        },
        // 模拟登录
        mockLogin() {
            this.$toast({
                title: false,
                type: 'eject',
                width: '4.2rem',
                height: 'auto',
                t_url: 'login/mockLogin',
                extr: {},
            });
        },
        // 消息中心
        showMessage() {
            this.$imconn.open(this.$imoption);
            const _this = this;
            // 监听回调
            this.$imconn.listen({
                onOpened: function (message) {
                    console.log(message, '用户已上线');
                },
                onClosed: function (message) {
                    console.log(message, '用户下线');
                    _this.$emit('isclose', true);
                },
                // 收到文本消息
                onTextMessage: function (message) {
                    if (message.from === _this.accept) {
                        _this.chatContent.push({
                            replyImg: '',
                            replyContent: message.data,
                        });
                        // _this.keepbottom();
                    }
                },
            });
            this.$toast({
                title: true,
                text: '消息中心',
                code: {
                    layerView: 'businessInstance',
                    configBusinessId: '02396FDA-C84F-4A96-A6BC-AAC600B425C8',
                },
                t_url: 'messageCenter/pages/Default',
                extr: { closeBtn: { } },
            });
            // this.isNIM = cookie.readCookie('isNIM');
            // if (this.isNIM === 'true') {
            //     this.$toast({
            //         title: true,
            //         text: '消息中心',
            //         code: {
            //             layerView: 'businessInstance',
            //             configBusinessId: '02396FDA-C84F-4A96-A6BC-AAC600B425C8',
            //         },
            //         t_url: 'messageCenter/pages/Default',
            //         extr: { closeBtn: { } },
            //     });
            // } else {
            //     this.$message.warning('云信账号未注册成功或网络状态异常，请退出重新登录！');
            // }
        },

        getCustomerType(tag) {
            const cacheTag = sessionStorage.getItem('customerType');
            if (cacheTag === tag) {
                return true;
            }
            return false;
        },
        // 设置按钮权限
        getShowMenuAdminBtn() {
            const RoleCodes = this.$takeTokenParameters('RoleCodes') || '';
            if (
                RoleCodes.indexOf('J10-01') !== -1
                || RoleCodes.indexOf('J70-01') !== -1
                || RoleCodes.indexOf('J90-01') !== -1
            ) {
                return true;
            }
            return false;
        },
        // 设置
        handleOpenMenuAdmin() {
            this.$emit('openIndependentView', 'setUp');
        },

        // 获取用户信息
        getUserInfo() {
            const userid = this.$takeTokenParameters('Uid');
            return this.$axios.$get(`/interfaceApi/basicplatform/users/${userid}`, { defEx: true });
        },

        // 拉取租户集合
        getTenantList() {
            return this.$axios.$get('/interfaceApi/platform/tenant_user_manage/dropdown_list', { defEx: true });
        },

        // 退出系统
        exitSystemRequest() {
            return this.$axios.$post('/interfaceApi/auth/logout', {}, { defEx: true });
        },
    },
    beforeDestroy() {
        this.destroy();
    },
};
</script>


<style lang="stylus" scope>
.detailed-infos
    position fixed
    top 0
    left 0
    right 0
    bottom 0
    z-index 99
    background rgba(0, 0, 0, .5)
    display flex
    justify-content center
    align-items center
    .iframe-alert
        border none
    .close-iframe
        display flex
        flex-direction column
        position relative
        width 100%
        height 100%
        z-index 999
        .close-iframe-header
            display flex
            justify-content space-between
            background #28292d
            height .5rem
            align-items center
            span:last-child
                color #fff
                font-size .2rem
                cursor:pointer
                padding-right .2rem
.content-box
    width 9rem
    background #fff
    height 6.5rem
    .el-select__caret
        position: relative;
        left: .2rem;
    .content-header
        display flex
        justify-content space-between
        align-items center
        padding 0 .2rem
        width 100%
        height .5rem
        background: #4F67AD
        color #fff
        font-size .2rem
        .close-alert
            transform rotate(45deg)
            font-weight 700
            cursor:pointer
    .user-card
        width 100%
        height .5rem
        background #FAFAFA
        line-height .5rem
        padding-left .4rem
        color #5588F1
        font-size .18rem
        display flex
        span
            margin-right .04rem
    .first-content
        display flex
        padding .3rem 0 .42rem
        position relative
        .tips{
            position absolute
            color #EB656F
            font-size .12rem
            font-weight 700
            right: .85rem;
            bottom: .2rem;
        }
        .box-1
            flex 1
            margin-left .4rem
            &:last-child
                margin-left 0
            div
                display flex
                align-items center
                &:last-child
                    margin-top .1rem
                /deep/ .el-input
                    width 2rem
                    height .38rem
                    background none
                    border 1px solid #D7D7D7
                    margin 0
                    .el-input__inner
                        background none
                        color #000
                span
                    margin-right .1rem
                p
                    width 1.3rem
                    font-size .16rem
        .two-box-1
            margin-left 0rem !important
    .first-content-two
        padding-top 0
        border-bottom 1px solid #D7D7D7
        .two-box-1
            margin-left 0rem !important
    .second-content
        padding .3rem 0 .4rem
        .second-1
            margin 0 0 .2rem .4rem
            font-size .2rem
            display flex
            align-items center
            p
                font-size .16rem
            .el-switch__core
                margin-left .2rem
                height .2rem
                width .46rem
            .el-switch__core::after
                width .16rem
                height .16rem
            .el-switch.is-checked .el-switch__core::after
                width .16rem
                height .16rem
                left: 111%;
        .second-2
            display flex
            padding 0 .4rem
            .passwordNNN
                display flex
                align-items center
                flex 1
                div
                    border 1px solid #ccc
                    width 2.6rem
                    display flex
                    align-items center
                &:last-child
                    margin-left .4rem
                input
                    width 2rem
                    height .38rem
                    background none
                    border 1px solid #D7D7D7
                    margin 0
                    border-radius .05rem
                    padding-left .2rem
                    border none
                span
                    margin-right .1rem
                p
                    width .9rem
                    font-size .16rem
    .third-content
        display flex
        font-size .16rem
        color #5588F1
        align-items center
        justify-content center
        span
            margin-right .02rem
        p
            margin-right .2rem
        div
            display flex
            align-items center
            justify-content center
            width 6.5rem
            height .36rem
            border 1px solid #D7D7D7;
            background #fff
            input
                width 5.5rem
                height .32rem
                border none
                padding-left .1rem
            span
                width 1rem
                text-align center
        button
            width .8rem
            height .34rem
            color #ffffff
            font-size .16rem
            border none
            background #1577D2
            cursor:pointer
.main_header
    height 0.8rem
    overflow hidden
    position relative
    display flex
    justify-content space-between
    .main_header_l
        display flex
        align-items center
        .logo
            // width 2.5rem
            // height 0.69rem
            display flex
            padding-left: 0.3rem
            img
                width 1.4rem
                margin-right: 0.14rem
        .versionMark
            width 0.8rem
            height 0.29rem
            &.basicEdition
                background: url('./../../assets/images/header/basicEdition.png') center no-repeat
                background-size: 100% 100%
            &.advancedEdition
                background: url('./../../assets/images/header/advancedEdition.png') center no-repeat
                background-size: 100% 100%
        .tenant
            margin 0 0.2rem
            cursor pointer
            .el-dropdown
                display: flex
                align-items end
                &:before
                    color #AEB2B7
                    font-size 0.16rem
                .el-dropdown-link
                    padding-left 0.1rem
                    font-size 0.16rem
                    line-height 0.22rem
                    font-weight 500
                    color #B8C2CC
                    display: flex
                    flex-direction row-reverse
                    align-items center
                    &:before
                        font-size 0.14rem
                        margin-left 0.15rem
    .main_header_r
        display flex
        align-items center
        margin 0 0.2rem
        >div
            margin-right 0.2rem
            &:last-child
                margin-right: 0
        .threeDimensional
            .el-dropdown-link
                width .24rem
                height .24rem
                font-size 0.3rem
                color #fff
        .el-input
            width 2rem
            background #363C4E
            border-radius 2px
            margin 0.15rem 0.2rem 0.15rem 0
            .el-input__inner
                height 0.3rem
                line-height 0.3rem
                padding 0 0.3rem 0 0.15rem
                background #253E7D
                border none
                color #fff
                &::-webkit-input-placeholder /* WebKit browsers */
                    font-size 0.12rem
                    font-weight 400
                    color rgba(255, 255, 255, 0.3)
                &::-moz-placeholder /* Mozilla Firefox 19+ */
                    font-size 0.12rem
                    font-weight 400
                    color rgba(255, 255, 255, 0.3)
                &:-ms-input-placeholder /* Internet Explorer 10+ */
                    font-size 0.12rem
                    font-weight 400
                    color rgba(255, 255, 255, 0.3)
            .el-input__suffix
                .el-input__icon
                    line-height 0.3rem
        .el-badge
            position relative
            .el-button
                padding 0
                border none
                background none
                font-size 0.2rem
                height 0.3rem
                line-height 0.3rem
                color #B8C2CC
                .icon
                    width 0.24rem
                    height 0.24rem
                    vertical-align -0.15em
                    fill currentColor
                    overflow hidden
            .el-badge__content
                border none
                background #D6000F
            .dot
                position absolute
                top -.04rem
                right -.04rem
                width .08rem
                height .08rem
                background #d6000f
                border-radius 50%

        .user
            height 0.24rem
            display flex
            align-items center
            .userImg
                width 0.24rem
                height 0.24rem
                border-radius 50%
                overflow hidden
                margin-right 0.1rem
                img
                    background rgba(0, 0, 0, 1)
                span
                    width 100%
                    height 100%
                    float left
                    font-size 0.16rem
                    line-height 0.24rem
                    text-align center
                    color #fff
            .el-dropdown
                height 100%
                .el-dropdown-link
                    float: left;
                    cursor pointer
                    line-height 0.24rem
                    font-size 0.18rem
                    font-weight 400
                    color #B8C2CC
        .signOutBtn
            .el-button
                span
                    font-size 0.16rem
        .ebchome
            margin 0.13rem 0.2rem 0.18rem 0
            cursor pointer
            .iconhome1
                font-size .28rem
                color #fff
.el-dropdown-menu
    .el-dropdown-menu__item
        text-align left

// 租户选择下拉菜单
.tenantDropdown
    padding 0
    .tenantDropdownBox
        max-height 3rem
        overflow-y auto
</style>
